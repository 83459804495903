import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {BaseUrl, ImageBaseUrl} from "../../Constants";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import MarkdownRenderer from "../common/MarkdownRenderer";
import {Helmet} from "react-helmet";
import * as React from "react";
import ReactGA from "react-ga4";

const PostDetail = () => {

    const [postUrl, setPostUrl] = useState("");
    const [postTitle, setPostTitle] = useState("");
    useEffect(() => {

        if (postUrl.length > 0) {
            ReactGA.send({
                hitType:"pageview",
                page:{postUrl},
                title:{postTitle},
            })
        }

    }, [postUrl,postTitle]);

    const {postLink} = useParams(); // Retrieve the post link from the URL

    const [postDetail, setPostDetails] = useState(null); // State to hold the post details

    // Fetch post details when the component mounts or when postLink changes
    useEffect(() => {
        axios.get(`${BaseUrl}post_detail?url=${postLink}`)
            .then((response) => {
                let postDetail = response.data;
                setPostDetails(postDetail);
                setPostUrl(postDetail.postLink);
                setPostTitle(postDetail.postTitle);
            })
            .catch((error) => {
                console.error("Error fetching post details:", error);
            });
    }, [postLink]); // Add postLink as a dependency to prevent infinite requests

    // Function to build the absolute URL for images or links
    const buildAbsoluteURL = (url) => {
        const isExternal = /^https?:\/\//.test(url);
        if (isExternal) {
            return url;
        }

        const host = window.location.origin;
        return `${host}/${url.replace(/^\/+/g, '')}`;
    };

    return (
        <div>
            {postDetail ? ( // Ensure postDetail is available before rendering

                <div>
                    <Helmet>
                        <title>{postDetail.postTitle}</title>
                        <meta name="description" content={postDetail.postDesc}/>
                        <meta property="og:title" content={postDetail.postTitle}/>
                        <meta property="og:description"
                              content={postDetail.postDesc}/>
                        <meta property="og:image" content={ImageBaseUrl+postDetail.postImage}/>
                    </Helmet>
                    <Stack direction="column" spacing={2} sx={{
                        maxWidth: "1100px",
                        margin: "0 auto", textAlign: 'start',
                        px: {xs: 2, sm: 3, md: 4}
                    }}>
                        <Typography variant="h1" sx={{fontWeight:'bold', fontSize:'38px'}}>{postDetail.postTitle}</Typography>
                        <img
                            src={ImageBaseUrl+postDetail.postImage}
                            alt={postDetail.postTitle}
                        />
                        <Box component="section" sx={{textAlign: 'start',fontFamily:'roboto, sans-serif'}}>
                            <MarkdownRenderer content={postDetail.postDetail}/>
                        </Box>
                    </Stack>
                </div>

            ) : null }
        </div>
    );
};

export default PostDetail;
