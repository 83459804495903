import './App.css';
import * as React from 'react';
import AppBar from "./components/AppBar";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import Home from "./components/home/Home";
import About from "./components/about/About";
import PostDetail from "./components/detail/PostDetail";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Privacy from "./components/privacy/Privacy";
import ReactGA from 'react-ga4';

function App() {
    ReactGA.initialize("G-SSK9K0HTL8");
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
        <div className="App">
            <Router>
            <AppBar/>
                <Routes>
                    <Route path="/"  element={<Home/>}/>
                    <Route path="/category/:categoryName"  element={<Home/>}/>
                    <Route path="/about" element={<About />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/:postLink" element={<PostDetail />} />
                </Routes>
            </Router>
        </div>
        </ThemeProvider>
    );
}

export default App;
