import Typography from "@mui/material/Typography";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid2';
import { Pagination, Stack, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import {BaseUrl, ImageBaseUrl} from "../../Constants";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

const Home = () => {

    ReactGA.send({
        hitType:"pageview",
        page:"/",
        title:"Home"
    })

    const { categoryName } = useParams();
    const [category, setCategory] = useState(categoryName ? categoryName.replace("-", " ") : "All");
    const [postList, setPostList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState(1);
    const [value, setValue] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    // Handle pagination change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const updatedCategory = category
        // Fetch posts for the selected category and current page
        axios
            .get(
                `${BaseUrl}post/category?category=${updatedCategory}&limit=${itemsPerPage}&offset=${(currentPage - 1) * itemsPerPage}`
            )
            .then((response) => {
                const totalCount = response.headers['x-total-count'];
                setPageCount(Math.ceil(totalCount / itemsPerPage));
                console.log(categoryList)
                console.log(category);

                setPostList(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [category, currentPage]);

    useEffect(() => {
        // Fetch category list
        axios
            .get(`${BaseUrl}category`)
            .then((response) => {
                setCategoryList(response.data);
                let item = response.data.map((item) => item.categoryName.toLowerCase())
                console.log(item);
                setValue(item.indexOf(category.toLowerCase()));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <div>
            <Helmet>
                <title>Howtodoandroid - Learn Android Development</title>
                <meta name="description"
                      content="A blog for Android developers to master Android development with clear, practical tutorials."/>
                <meta property="og:title" content="Howtodoandroid - Learn Android Development"/>
                <meta property="og:description"
                      content="Discover tutorials, tips, and best practices to help Android developers master app development on Howtodoandroid."/>
                <meta property="og:url" content="https://www.howtodoandroid.com"/>
            </Helmet>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        maxWidth: "1300px",
                        margin: "0 auto",
                        alignItems: "center",
                        alignContent: "center",
                        px: {xs: 2, sm: 3, md: 4},
                    }}
                >
                    {categoryList.map((category) => (
                        <Tab
                            label={category.categoryName}
                            onClick={() => {
                                navigate(`/category/${category.categoryName.replace(/\s+/g, '-')}`);
                                setCategory(category.categoryName);
                                setCurrentPage(1); // Reset to page 1 when category changes
                        }}
                        key={category.categoryName}
                    />
                ))}
            </Tabs>
            </Box>
            <Grid
                container
                spacing={3}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                    maxWidth: "1300px",
                    margin: "0 auto",
                    alignItems: "center",
                    alignContent: "center",
                    my: { xs: 2, sm: 4, md: 5 },
                    px: { xs: 2, sm: 3, md: 4 },
                }}
            >
                {postList.map((post, index) => (
                    <Grid size={{ xs: 4, sm: 4, md: 4 }} key={index}>
                        <Card>
                            <CardActionArea
                                onClick={() => {
                                    navigate(`/${post.postLink}`);
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={ImageBaseUrl+post.postImage}
                                    alt={post.postTitle}
                                />
                                <CardContent>
                                    {/* Title with max 2 lines */}
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitBoxOrient: 'vertical',
                                            textAlign:'start',
                                            WebkitLineClamp: 2,  // Limits to 2 lines
                                        }}
                                    >
                                        {post.postTitle}
                                    </Typography>

                                    {/* Description with max 3 lines */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            textAlign:'start',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 3,  // Limits to 3 lines
                                        }}
                                    >
                                        {post.postDesc}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>
                ))}
            </Grid>

            {/* Pagination controls */}
            <Stack
                spacing={2}
                sx={{
                    margin: "0 auto",
                    alignItems: "center",
                    m: 2,
                }}
            >
                <Pagination
                    count={pageCount} // Total number of pages
                    page={currentPage} // Bind to current page
                    onChange={handlePageChange} // Handle page change
                    color="primary"
                />
            </Stack>
        </div>
    );
};

export default Home;
