import React from 'react';
import { Box, Typography, Card, CardMedia, Grid } from '@mui/material';
import {ImageBaseUrl} from "../../Constants";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
const About = () => {

    ReactGA.send({
        hitType:"pageview",
        page:"/about",
        title:"About"
    })

    return (
        <div>
            <Helmet>
                <title>About Me - Howtodoandroid</title>
                <meta name="description"
                      content="Learn more about Velmurugan M, a Lead Software Engineer passionate about Android development and innovative mobile solutions."/>
                <meta property="og:title" content="About Me - Howtodoandroid"/>
                <meta property="og:description"
                      content="Discover the journey of Velmurugan M, a Lead Software Engineer with extensive experience in Android development."/>
                <meta property="og:image" content={`${ImageBaseUrl}wp-content/uploads/2024/github_profile.png`}/>
                <meta property="og:url" content="https://www.howtodoandroid.com/about"/>
            </Helmet>
            <Box className="about" sx={{
                padding: 3,
                maxWidth: "1300px",
                margin: "0 auto",
                alignItems: "center",
                alignContent: "center",
                px: {xs: 2, sm: 3, md: 4}
            }}>
                <Typography variant="h4" gutterBottom>
                    About Me
                </Typography>

                <Card sx={{mb: 3}}>
                    <CardMedia
                        component="img"
                        image={`${ImageBaseUrl}/wp-content/uploads/2024/github_profile.png`}
                        alt="Profile"
                        sx={{width: '100%', height: 'auto'}}
                    />
                </Card>

                <Typography variant="body1" sx={{textAlign:'start'}}>
                    As a Lead Software Engineer with extensive experience in Android application development, I am passionate about creating innovative and user-centric mobile solutions. I thrive on transforming complex problems into seamless experiences and continuously strive to enhance my skills in the ever-evolving tech landscape.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Connect With Me
                </Typography>

                <Box id="badges" sx={{display: 'flex', gap: 2, mb: 3, justifyContent: "center"}}>
                    <a href="https://www.linkedin.com/in/velmurugan-m/" target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://img.shields.io/badge/LinkedIn-blue?style=for-the-badge&logo=linkedin&logoColor=white"
                            alt="LinkedIn Badge" style={{width: '120px', height: '40px'}}/>
                    </a>
                    <a href="https://www.reddit.com/user/howtodoandroid_com" target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://img.shields.io/badge/Reddit-FF4500?style=for-the-badge&logo=reddit&logoColor=white"
                            alt="Reddit Badge" style={{width: '120px', height: '40px'}}/>
                    </a>
                    <a href="https://medium.com/@velmurugan-murugesan" target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://img.shields.io/badge/Medium-12100E?style=for-the-badge&logo=medium&logoColor=white"
                            alt="Medium Badge" style={{width: '120px', height: '40px'}}/>
                    </a>
                    <a href="https://github.com/velmurugan-murugesan" target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white"
                            alt="Guthub Badge" style={{width: '120px', height: '40px'}}/>
                    </a>

                </Box>

            </Box>
        </div>
    );
};

export default About;
